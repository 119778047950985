@tailwind base;
@tailwind components;
@tailwind utilities;
/* @font-face {
  font-family: lato;
  src: url("./Assets/Lato/Lato-Regular.ttf");
} */

@layer base {
  :root {
    /* text */
    --tw-text-primary: #6f7473;
    --tw-text-secondary: #8a8f8e;
    --tw-text-placeholder: #cccccc;
    --tw-text-button: #4c4c4c;
    --tw-text-greentext: #49ad71;
    --tw-text-redtext: #fd5868;
    /* background */
    --tw-background-container: #ffffff;
    --tw-background-secondary: #e9e9e9;
    --tw-background-card: #ffffff;
    --tw-background-networkcard: #f7f7f7;
    --tw-background-disable: #6f7473;
    --tw-background-loaderbar: #e9e9e9;
    /* border */
    --tw-border-primary: #e0e0e0;
    --tw-border-secondary: #808080;
    --tw-border-inputHighlight: #9fc966;
  }
  html[data-theme="dark"] {
    --tw-text-primary: #eaeaeb;
    --tw-text-secondary: #96969c;
    --tw-text-placeholder: #666666;
    --tw-text-button: #ffffff;
    --tw-text-greentext: #49ad71;
    --tw-text-redtext: #fd5868;
    /* background */
    --tw-background-container: #0f0f0f;
    --tw-background-secondary: #353535;
    --tw-background-card: #1f1f1f;
    --tw-background-networkcard: #1f1f1f;
    --tw-background-disable: #6f7473;
    --tw-background-loaderbar: rgba(224, 224, 224, 0.3);
    /* border */
    --tw-border-primary: #353535;
    --tw-border-secondary: #808080;
    --tw-border-inputHighlight: #9fc966;
  }
}
body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background: black;
  box-sizing: border-box !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input:focus {
  outline: none !important;
  /* box-shadow: 0 0 10px #121313 !important; */
}

/* Handle on hover */

.blockend-container ::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}
/* Track */
.blockend-container ::-webkit-scrollbar-track {
  background: var(--tw-border-primary);
  border-radius: 5px;
}

/* Handle */
.blockend-container ::-webkit-scrollbar-thumb {
  background: var(--tw-text-secondary);
  border-radius: 5px;
}

/* Handle on hover */
.blockend-container ::-webkit-scrollbar-thumb:hover {
  background: var(--tw-text-secondary);
}
.blockend-container ::-webkit-scrollbar-corner {
  background: var(--tw-border-primary);
}

thead,
tbody {
  display: block !important;
  width: 100% !important;
}
